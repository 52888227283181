<template>
  <div class="sidebar-filter">
    <div class="sidebar-filter__input-group">
      <DateRangePicker
        :value="filterDateRange"
        :label="$t('partner-portal.common.date-range')"
        :min-date="minDate"
        @date-change="applyDateChanges"
      />
    </div>

    <div class="sidebar-filter__input-group">
      <div class="sidebar-filter__input-title">
        {{ $t('partner-portal.top-charts.sidebar.filters.gateway') }}
      </div>

      <DropdownFilter
        class="sidebar-filter__dropdown-content"
        :title="$t('partner-portal.top-charts.sidebar.filters.all-gateways')"
        :items="gatewaysList"
        :active-items="selectedGatewaysList"
        @apply-changes="applyGatewaysChanges"
        @discard-changes="discardGatewaysChanges"
      />
    </div>

    <div
      v-if="needCurrenciesFilter"
      class="sidebar-filter__input-group"
    >
      <div class="sidebar-filter__input-title">
        {{ $t('partner-portal.top-charts.sidebar.filters.cryptocurrencies') }}
      </div>

      <DropdownFilter
        class="sidebar-filter__dropdown-content"
        :title="$t('partner-portal.top-charts.sidebar.filters.all-cryptocurrencies')"
        :items="currenciesList"
        :active-items="selectedCurrenciesList"
        @apply-changes="applyCurrenciesChanges"
        @discard-changes="discardCurrenciesChanges"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import DropdownFilter from '@/components/DropdownFilter';
import DateRangePicker from '@/components/DateRangePicker';

export default {
  name: 'TopChartsSidebarFilter',

  components: {
    DropdownFilter,
    DateRangePicker,
  },

  props: {
    selectedDateFilter: {
      type: Object,
      required: true,
    },

    selectedGatewaysList: {
      type: Array,
      default: () => [],
    },

    selectedCurrenciesList: {
      type: Array,
      default: () => [],
    },

    needCurrenciesFilter: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      dateRange: null,
    };
  },

  computed: {
    ...mapGetters('filterStore', {
      gatewaysList: 'gatewaysList',
      currenciesList: 'currenciesList',
      minDate: 'minDate',
    }),

    filterDateRange() {
      if (this.dateRange) return this.dateRange;
      return this.selectedDateFilter;
    },
  },

  methods: {
    applyDateChanges({ start: from, end: to }) {
      this.dateRange = {
        start: from, end: to,
      };

      this.$emit('apply-date-changes', {
        from, to,
      });
    },

    applyGatewaysChanges(changes) {
      this.$emit('apply-gateways-changes', changes);
    },

    discardGatewaysChanges() {
      this.$emit('discard-gateways-changes');
    },

    applyCurrenciesChanges(changes) {
      this.$emit('apply-currencies-changes', changes);
    },

    discardCurrenciesChanges() {
      this.$emit('discard-currencies-changes');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';
@import '~@/assets/scss/icons';

.sidebar-filter {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px 24px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px $secondary-border-color;

  &__input-group {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__input-value {
    display: flex;
  }

  &__input-value-image {
    height: 16px;
    width: 16px;
    margin-right: 8px;
  }

  &__input-title {
    font-size: 15px;
    line-height: 18px;
    font-family: $font-regular;
    margin-bottom: 6px;
  }

  &__date-range {
    display: flex;
  }

  &__calendar-button {
    height: 16px;
    width: 16px;
    margin-right: 10px;
  }
}
</style>
